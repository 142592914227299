import googleLogo from "../images/google-logo.svg";
import youTubeLogo from "../images/youtube-logo.svg";
import mcDonaldsLogo from "../images/mcdonalds-logo.svg";
import lollapaloozaLogo from "../images/lollapalooza-logo.svg";
import sonicLogo from "../images/sonic-logo.svg";
import akadLogo from "../images/akad-logo-clean.svg";
import agoraLogo from "../images/agora-logo.svg";
import externalLinkIcon from "../images/external-link-icon.svg";
import ustreetLogo from "../images/ustreet-logo.svg";
import "./projects.scss";

const data = [
  {
    logoImage: ustreetLogo,
    logoPercentHeight: "46",
    themeColor: "23, 34, 61",
    title: "AI Advisor",
    projectType: "Platform",
    startYear: "2023",
    endYear: "",
    roles: ["Sr. Frontend Engineer"],
    links: [{ label: "Live", url: "https://app.ustreet.ai" }],
    highlights: [
      "TypeScript",
      "React",
      "NodeJS",
      "CSS",
      "SASS",
      "BEM",
      "Git",
      "AWS Amplify",
      "charting",
      "Storybook",
      "LLM",
      "Chat",
    ],
    paragraphs: [
      <span>
        Built the investment advice platform
        <strong> from scratch to production</strong> covering auth modules,
        onboarding journeys, custom chat interface interacting with LLM calls,
        reports, custom charting library, etc;
      </span>,
      <span>
        Conducted a thorough comparison of third-party and open-source{" "}
        <strong>charting libraries</strong> to determine the most
        performance-efficient technology to be used company-wise;
      </span>,
    ],
  },
  {
    logoImage: ustreetLogo,
    logoPercentHeight: "46",
    themeColor: "23, 34, 61",
    title: "Institutional Website",
    projectType: "Website",
    startYear: "2023",
    endYear: "",
    roles: ["Sr. Frontend Engineer"],
    links: [{ label: "Live", url: "https://ustreet.ai" }],
    highlights: [
      "JavaScript",
      "React",
      "GatsbyJS",
      "CSS",
      "SASS",
      "BEM",
      "Git",
      "Netlify",
    ],
    paragraphs: [
      <span>
        Built the institutional website from ground up, including visual
        designs, animations, frontend implementation and publishing.
      </span>,
      <span>
        Used GatsbyJS to generate <strong>static pages</strong> and GraphQL to
        fetch data from a <strong>headless CMS</strong> to make content updates
        seamless;
      </span>,
    ],
  },
  {
    logoImage: googleLogo,
    logoPercentHeight: "46",
    themeColor: "72, 133, 237",
    title: "Google VideoMaker",
    projectType: "Platform",
    startYear: "2020",
    endYear: "2021",
    roles: ["Sr. Frontend Engineer", "Tech Lead"],
    links: [
      { label: "Live", url: "https://marketingkit.withgoogle.com/videomaker" },
    ],
    highlights: [
      "JavaScript",
      "Angular",
      "CSS",
      "SASS",
      "BEM",
      "Git",
      "Google Cloud Platform",
      "Python",
      "FFMPEG",
      "Analytics",
      "Internationalization",
    ],
    paragraphs: [
      <span>
        As a Googler, led frontend development{" "}
        <strong>from scratch to production</strong> of the video generation
        platform;
      </span>,
      <span>
        Worked with Google internal tools on daily basis, contributing to the
        improvement of processes like code reviews and best practices;
      </span>,
      <span>
        Responsible for maintaining the <strong>Python backend</strong>, mainly
        designed to support vendor integrations;
      </span>,
    ],
  },
  {
    logoImage: youTubeLogo,
    logoPercentHeight: "38",
    themeColor: "255, 11, 0",
    title: "YouTube Works",
    projectType: "Portal",
    startYear: "2020",
    endYear: "2021",
    roles: ["Sr. Frontend Engineer", "Tech Lead"],
    links: [
      { label: "About", url: "https://www.youtube.com/ads/youtube-works/" },
    ],
    highlights: [
      "JavaScript",
      "TypeScript",
      "Angular",
      "NodeJS",
      "CSS",
      "SASS",
      "BEM",
      "Git",
      "Firestore",
      "Firebase Auth",
      "Cloud Functions",
      "Google Cloud Platform",
      "Analytics",
    ],
    paragraphs: [
      <span>
        Led frontend development of new features and bugfixes on this existing
        project, running <strong>client meetings</strong> and demos on weekly
        basis;
      </span>,
      <span>
        Dealt with a few Firebase products like <strong>Auth</strong>,{" "}
        <strong>Firestore</strong> and <strong>Cloud Functions</strong>;
      </span>,
    ],
  },
  {
    logoImage: mcDonaldsLogo,
    logoPercentHeight: "50",
    themeColor: "255, 204, 0",
    title: "McDonald's Spooky selfie",
    projectType: "Web App",
    startYear: "2019",
    endYear: "",
    roles: ["Sr. Frontend Engineer"],
    links: [
      {
        label: "Live",
        url: "https://www.campaignlive.co.uk/article/mcdonalds-spooky-selfie-r-ga-london/1662949",
      },
    ],
    highlights: [
      "JavaScript",
      "React",
      "CSS",
      "Styled Components",
      "Git",
      "AWS S3",
      "WebGL",
      "Visage SDK",
      "Face Tracking",
    ],
    paragraphs: [
      <span>
        Worked for a couple of months on an immersive mobile app full of{" "}
        <strong>CSS animations</strong> with <strong>Visage SDK</strong>{" "}
        integration via <strong>WebGL</strong> to perform{" "}
        <strong>face tracking</strong> and an HTML5 Canvas implementation to
        manipulate 3D element's sizing/position/rotation;
      </span>,
    ],
  },
  {
    logoImage: lollapaloozaLogo,
    logoPercentHeight: "50",
    themeColor: "132, 50, 199",
    title: "Team next no LollaBR",
    projectType: "Journey",
    startYear: "2019",
    endYear: "",
    roles: ["Sr. Frontend Engineer"],
    links: [
      {
        label: "Live",
        url: "https://www.behance.net/gallery/99496977/Team-Next-no-Lollapalooza-Brasil",
      },
    ],
    highlights: [
      "JavaScript",
      "React",
      "Redux",
      "NextJS",
      "CSS",
      "SASS",
      "StoryBook",
      "Git",
      "Docker",
      "AWS ECS",
      "AWS ECR",
      "Analytics",
    ],
    paragraphs: [
      <span>
        Implemented the <strong>CI/CD</strong> setup with{" "}
        <strong>Docker</strong> + <strong>AWS ECR</strong> +{" "}
        <strong>AWS ECS</strong> + <strong>Git</strong> to make it seamless to
        deploy, rollback or scale up when needed;
      </span>,
      <span>
        Since it required some <strong>server-side rendered</strong> pages
        alongside the static pages, <strong>NextJS</strong> was used to handle
        the rendering needs;
      </span>,
    ],
  },
  {
    logoImage: sonicLogo,
    logoPercentHeight: "53",
    themeColor: "228, 0, 70",
    title: "SONIC Web Order Ahead",
    projectType: "Web App",
    startYear: "2019",
    endYear: "2020",
    roles: ["Sr. Frontend Engineer"],
    links: [{ label: "Live", url: "https://order.sonicdrivein.com/" }],
    highlights: [
      "TypeScript",
      "React",
      "Redux",
      "Sagas",
      "CSS",
      "SASS",
      "StoryBook",
      "Git",
      "Google Cloud Platform",
      "Python",
      "FFMPEG",
      "Analytics",
    ],
    paragraphs: [
      <span>
        As part of a three-engineers team, worked for one year creating a
        full-featured food-ordering app
      </span>,
      <span>
        Having a <strong>screen readers</strong> friendly UI was strongly
        required so tweaking custom components in order to make them compliant
        with <strong>WCAG standards</strong> took a significant amount of time;
      </span>,
      <span>
        Had the chance to use React + TypeScript and experienced the benefits of
        TS types on a large-scale app;
      </span>,
    ],
  },
  {
    logoImage: akadLogo,
    logoPercentHeight: "39",
    themeColor: "23, 54, 95",
    title: "Akad B2B Quotation",
    projectType: "Journey",
    startYear: "2022",
    endYear: "",
    roles: ["Sr. Frontend Engineer", "Tech Lead"],
    links: [
      {
        label: "Live",
        url: "https://empresarial.akadseguros.com.br/nao-autorizado",
      },
    ],
    highlights: [
      "JavaScript",
      "React",
      "GatsbyJS",
      "Context API",
      "CSS",
      "SASS",
      "BEM",
      "Lottie",
      "StoryBook",
      "Git",
      "Azure",
      "Analytics",
      "Recruitment",
    ],
    paragraphs: [
      <span>
        Led a team of <strong>15 frontend engineers</strong>, running group
        meetings, giving technical support, and being the frontend
        representative for other departments;
      </span>,
      <span>
        Experienced <strong>M&A + internalization</strong>, dealing with
        integrations between legacy and new projects, mixed teams and
        conflicting culture perspectives;
      </span>,
      <span>
        From the new product's/growth perspective based on client conversations,
        designed some branded functional POCs to validate hypotheses on making
        it easier to bring non-tech partners;
      </span>,
    ],
  },
  {
    logoImage: agoraLogo,
    logoPercentHeight: "50",
    themeColor: "1, 197, 146",
    title: "Ágora ",
    projectType: "Website",
    startYear: "2018",
    endYear: "",
    roles: ["Sr. Frontend Engineer"],
    links: [{ label: "Live", url: "https://www.agorainvestimentos.com.br/" }],
    highlights: [
      "JavaScript",
      "React",
      "GatsbyJS",
      "CSS",
      "SASS",
      "BEM",
      "Lottie",
      "Git",
      "AWS S3",
      "Analytics",
    ],
    paragraphs: [
      <span>
        Worked <strong>from concept to production</strong> with two other
        engineers on a 100+ pages institutional website for an investments
        company;
      </span>,
      <span>
        The client asked for a solution covering a{" "}
        <strong>file-based CMS</strong>, GatsbyJS + GraphQL + markdown files
        made the trick by providing <strong>static pages generation</strong>{" "}
        with markdown data injected into pages through GraphQL queries;
      </span>,
    ],
  },
];

const block = "projects";
function Projects() {
  return (
    <>
      <h1>Recent work</h1>
      <div className={block}>
        {data.map((project) => {
          return (
            <div className={`${block}__card`}>
              <div className={`${block}__cover-wrapper`}>
                <div
                  className={`${block}__card-cover`}
                  style={{
                    backgroundColor: `rgb(${project.themeColor})`,
                    backgroundImage: `url(${project.logoImage})`,
                    backgroundSize: `auto ${project.logoPercentHeight}%`,
                  }}
                >
                  {!!project.links?.length && (
                    <a
                      href={project.links[0].url}
                      className={`${block}__link`}
                      target="_new"
                    >
                      <img
                        className={`${block}__link-icon`}
                        src={externalLinkIcon}
                        alt=""
                      />
                    </a>
                  )}
                </div>
              </div>
              <div
                className={`${block}__project-title`}
                style={{
                  backgroundColor: `rgba(${project.themeColor}, .08)`,
                  borderLeft: `solid 2px rgba(${project.themeColor}, 1)`,
                  borderRight: `solid 2px rgba(${project.themeColor}, 1)`,
                }}
              >
                <div className={`${block}__project-header`}>
                  {/* <div className={`${block}__project-label`}>
                  {project.projectType}
                </div> */}
                </div>
                {/* <div className={`${block}__project-label`}>
                {project.projectType}
              </div> */}
                <div className={`${block}__title-inner`}>
                  {project.title}&nbsp;
                  <span
                    className={`${block}__project-type`}
                    style={{ color: `rgba(${project.themeColor}, 1)` }}
                  >
                    &#8226;
                  </span>
                  &nbsp;
                  {project.projectType}
                </div>
                <div className={`${block}__pills`}>
                  <div
                    className={`${block}__pill ${block}__pill--date`}
                    style={{
                      backgroundColor: `rgba(${project.themeColor}, .2)`,
                    }}
                  >
                    {project.startYear}
                    {project?.endYear ? `/${project.endYear}` : null}
                  </div>
                  {project.roles.map((role) => {
                    return (
                      <div
                        className={`${block}__pill ${block}__pill--role`}
                        style={{
                          backgroundColor: `rgba(${project.themeColor}, .2)`,
                        }}
                      >
                        {role}
                      </div>
                    );
                  })}
                  {project.highlights.map((highlight) => {
                    return (
                      <div
                        className={`${block}__pill`}
                        style={{
                          backgroundColor: `rgba(${project.themeColor}, .2)`,
                        }}
                      >
                        {highlight}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                className={`${block}__project-content`}
                style={{
                  borderLeft: `solid 2px rgba(${project.themeColor}, 1)`,
                  borderRight: `solid 2px rgba(${project.themeColor}, 1)`,
                  borderBottom: `solid 2px rgba(${project.themeColor}, 1)`,
                }}
              >
                <ul className={`${block}__paragraphs`}>
                  {project.paragraphs.map((paragraph) => {
                    return <li>{paragraph}</li>;
                  })}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default Projects;
