import { Link } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import cn from "classnames";
import classNames from "classnames";
import Lottie from "react-lottie";
import * as animationData from "../112240-matched.json";
import logo from "../images/tenyks-logo.png";
import "./page-tenyks-game.scss";

const oopsOptions = [
  `Don't stop, try another skill card!`,
  `Pick another one!`,
  `Stay strong!`,
  `Come on! You can do it!`,
  `Almost there, go for it!`,
];
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const block = "page-tenyks-game";
function PageTenyksGame() {
  // const [ref, inView] = useInView({
  //   rootMargin: "-65px",
  //   initialInView: true,
  //   trackVisibility: true,
  //   delay: 100,
  // });
  const [stagedRequirement, setStagedRequirement] = useState();
  const [modal, setModal] = useState();
  const [started, setStarted] = useState();
  const [touched, setTouched] = useState(false);
  const [info, setInfo] = useState(
    "[i] Tap any job requirement on the left column to start!"
  );
  const [cards, setCards] = useState([
    {
      requirementText:
        "3+ years of professional experience building SaaS products, with React and Typescript",
      skills: (
        <div className={`${block}__skill-wrapper`}>
          <div className={`${block}__skill-item`}>
            I have sixteen years of experience delivering all kinds of web
            applications, including but not limited to SaaS products.
          </div>
        </div>
      ),
      niceToHave: false,
      requiredVisible: false,
      skillsVisible: false,
      match: false,
      requirementShaking: false,
      skillShaking: false,
      partialMatch: false,
    },
    {
      requirementText:
        "Experience with highly interactive or data-intensive web applications.",
      skills: (
        <div className={`${block}__skill-wrapper`}>
          <div className={`${block}__skill-item`}>
            I have worked on many projects with plenty of backend integrations
            to access data, from the old-fashioned SOAP to modern GraphQL
            queries, exploring client/server aspects like offline availability,
            transaction controls, etc.
          </div>
        </div>
      ),
      niceToHave: false,
      requiredVisible: false,
      skillsVisible: false,
      match: false,
      requirementShaking: false,
      skillShaking: false,
      partialMatch: false,
    },
    {
      requirementText: "Experience in producing and consuming RESTful APIs.",
      skills: (
        <div className={`${block}__skill-wrapper`}>
          <div className={`${block}__skill-item`}>
            I started my career as a full-stack engineer, then I've had the
            opportunity to understand the entire chain of software delivery,
            from database modeling to production health checks after some years
            of API design/implementation.
          </div>
        </div>
      ),
      niceToHave: false,
      requiredVisible: false,
      skillsVisible: false,
      match: false,
      requirementShaking: false,
      skillShaking: false,
      partialMatch: false,
    },
    {
      requirementText:
        "The ability to work in an unstructured, self-directed environment.",
      skills: (
        <div className={`${block}__skill-wrapper`}>
          <div className={`${block}__skill-item`}>
            I've been working with international teams for a couple of years
            since the pandemic hit and I really like to work in an environment
            in which process, alignment, and transparency are the keys to
            working with maximum ownership.
          </div>
        </div>
      ),
      niceToHave: false,
      requiredVisible: false,
      skillsVisible: false,
      match: false,
      requirementShaking: false,
      skillShaking: false,
      partialMatch: false,
    },
    {
      requirementText:
        "Experience with at least one major cloud provider (AWS, GCP, Azure)",
      skills: (
        <div className={`${block}__skill-wrapper`}>
          <div className={`${block}__skill-item`}>
            I have experience with all of them in products like scalable
            computing, CI/CD, static hosting, container-based automated
            deployments, domains, certificates and DNS management, etc.
          </div>
        </div>
      ),
      niceToHave: false,
      requiredVisible: false,
      skillsVisible: false,
      match: false,
      requirementShaking: false,
      skillShaking: false,
      partialMatch: false,
    },
    {
      requirementText: "Ability to communicate clearly and succinctly.",
      skills: (
        <div className={`${block}__skill-wrapper`}>
          <div className={`${block}__skill-item`}>Agreed.</div>
        </div>
      ),
      niceToHave: false,
      requiredVisible: false,
      skillsVisible: false,
      match: false,
      requirementShaking: false,
      skillShaking: false,
      partialMatch: false,
    },
    {
      requirementText:
        "A love for building, especially novel product experiences.",
      skills: (
        <div className={`${block}__skill-wrapper`}>
          <div className={`${block}__skill-item`}>
            As mentioned on my LinkedIn bio, I'm "...passionate about Frontend
            and its intersection with copy, growth, product and user
            experience..." I really like the idea of working for a self-owned
            product, after some amazing years working for an agency it looks to
            me the kind of environment I can contribute the most.
          </div>
        </div>
      ),
      niceToHave: false,
      requiredVisible: false,
      skillsVisible: false,
      match: false,
      requirementShaking: false,
      skillShaking: false,
      partialMatch: false,
    },
    {
      requirementText:
        "Care and empathy for users. We fall in love with our users, not our products.",
      skills: (
        <div className={`${block}__skill-wrapper`}>
          <div className={`${block}__skill-item`}>
            I love my users too and I'm looking for a company where user
            behavior is the most important factor in taking product/strategy
            decisions, even this gamified experience is being tracked by Hotjar
            in order to give me some useful insights that will help me to evolve
            and adjust it.
          </div>
        </div>
      ),
      niceToHave: false,
      requiredVisible: false,
      skillsVisible: false,
      match: false,
      requirementShaking: false,
      skillShaking: false,
      partialMatch: false,
    },
    {
      requirementText: "Experience with Computer vision is a bonus.",
      skills: (
        <div className={`${block}__skill-wrapper`}>
          <div className={`${block}__skill-item`}>
            Have worked with some Google Vision APIs before training models and
            using them to classify images afterward however I'd better remember
            to set a budget limit on my GCP credit card next time I play with
            it.
          </div>
        </div>
      ),
      niceToHave: true,
      requiredVisible: false,
      skillsVisible: false,
      match: false,
      requirementShaking: false,
      skillShaking: false,
      partialMatch: false,
    },
    {
      requirementText: "Preferably based in UK or Europe",
      skills: (
        <div className={`${block}__skill-wrapper`}>
          <div className={`${block}__skill-item`}>
            I'm based in Sao Paulo, Brazil however I have the flexibility to
            work in the UK timezone as I did during a project for McDonald's UK.
          </div>
        </div>
      ),
      niceToHave: false,
      requiredVisible: false,
      skillsVisible: false,
      match: false,
      requirementShaking: false,
      skillShaking: false,
      partialMatch: false,
    },
  ]);

  const [score, setScore] = useState(0);
  const [requirementsShake, setRequirementsShake] = useState(false);
  const [skillsShake, setSkillsShake] = useState(false);

  const [requirements, setRequirements] = useState(
    cards
      .map((a) => [Math.random(), a])
      .sort((a, b) => a[0] - b[0])
      .map((a) => ({ ...a[1] }))
  );

  const [skills, setSkills] = useState(
    cards
      .map((a) => [Math.random(), a])
      .sort((a, b) => a[0] - b[0])
      .map((a) => ({ ...a[1] }))
  );

  const tryAgain = useCallback(
    (card) => {
      console.log("TRY AGAIN");
      // card.skillsVisible = false;

      const localSkills = skills.map((requirement) => {
        if (card.requirementText === requirement.requirementText) {
          requirement.skillsVisible = false;

          return requirement;
        }

        return requirement;
      }, []);

      setSkills(localSkills);
    },
    [skills]
  );

  const requirementClick = useCallback((card) => {
    if (card.match) {
      matchAlert(card);
      return;
    }
  });

  const flipRequirement = useCallback(
    (card) => {
      if (!touched) {
        setInfo(`Nice move! Now it's time to pick a skill on the right column`);
      }

      setTouched(true);
      // if (card.match) {
      //   matchAlert(card);
      //   console.log("Matched!", card);
      //   return;
      // }

      setRequirementsShake(false);

      if (stagedRequirement) {
        setSkillsShake(false);

        setTimeout(() => {
          setSkillsShake(true);
        }, 0);
        return;
      }

      const localRequirements = requirements.map((requirement) => {
        if (card.requirementText === requirement.requirementText) {
          requirement.requiredVisible = true;

          setStagedRequirement(requirement);
          return requirement;
        }
        return requirement;
      });

      setRequirements(localRequirements);
    },
    [requirements, stagedRequirement, touched]
  );

  const dismissModal = useCallback(() => {
    setModal(null);
  }, []);

  const matchAlert = useCallback(
    (card) => {
      setModal(
        <div
          className={`${block}__modal-panel ${block}__modal-panel--full ${block}__modal-panel--light`}
        >
          <div className={`${block}__welcome-modal-wrappwer`}>
            <div className={`${block}__modal-titlebar`}>It's a Match!</div>

            <div className={`${block}__modal-content`}>
              <div className={`${block}__match-modal-lottie`}>
                <Lottie options={defaultOptions} />
              </div>
              <div className={`${block}__match-modal-points-wrapper`}>
                {card.partialMatch ? (
                  <div
                    className={cn(
                      `${block}__match-modal-points-badge`,
                      `${block}__match-modal-points-badge--partial`
                    )}
                  >
                    Partial Match 0.5 point
                  </div>
                ) : (
                  <div className={`${block}__match-modal-points-badge`}>
                    Full Match +1 point
                  </div>
                )}
              </div>
              <div className={`${block}__modal-text`}>
                <strong>
                  {card.niceToHave ? "Nice to have" : "Must have"}
                </strong>
                : {card.requirementText}
                <br />
                <br />
                <strong>Skills</strong>
                :<br />
                {card.skills}
              </div>
            </div>
            <div className={`${block}__modal-footer`}>
              <button
                className={`${block}__modal-button`}
                onClick={dismissModal}
              >
                Continue!
              </button>
            </div>
          </div>
        </div>
      );
    },
    [dismissModal]
  );

  const flipSkill = useCallback(
    (card) => {
      setSkillsShake(false);
      setInfo("");
      // setTouched(true);

      if (card.skillsVisible && !card.match) {
        const openSkills = skills.map((requirement) => {
          if (requirement.requirementText === card.requirementText) {
            requirement.skillsVisible = false;
          }

          return requirement;
        });

        setSkills(openSkills);
        return;
      }

      if (!stagedRequirement || card.match) {
        setRequirementsShake(false);
        console.log("REMOVE SHAKE");

        setTimeout(() => {
          setRequirementsShake(true);
        }, 0);
        return;
      }

      const openSkills = skills.map((requirement) => {
        if (!requirement.match && requirement.skillsVisible) {
          requirement.skillsVisible = false;
        }

        return requirement;
      });

      if (openSkills.length) {
        console.log("open", openSkills);
        setSkills(openSkills);
      }

      let match = false;

      if (card?.skillsVisible) return tryAgain(card);

      const localSkills = skills.map((requirement) => {
        if (card.requirementText === requirement.requirementText) {
          requirement.skillsVisible = true;

          if (stagedRequirement.requirementText === card.requirementText) {
            match = true;
          }

          if (match) {
            requirement.match = true;
            stagedRequirement.match = true;
            setScore(card.partialMatch ? score + 0.5 : score + 1);
            requirement.requiredVisible = true;
            setStagedRequirement(null);
            matchAlert(card);
          }

          // setStagedRequirement(requirement);
          return requirement;
        }

        return requirement;
      });

      setSkills(localSkills);
    },
    [skills, stagedRequirement, score, tryAgain, matchAlert]
  );

  const start = useCallback(() => {
    dismissModal();
    setStarted(true);
  }, [dismissModal]);

  const oopsMessage = useCallback(() => {
    return oopsOptions[Math.floor(Math.random() * oopsOptions.length)];
  }, []);

  const skillCardBackChunk = useCallback(
    (card) => {
      if (card.match) {
        return (
          <>
            <div className={`${block}__match-modal-points-wrapper`}>
              {card.partialMatch ? (
                <div
                  className={cn(
                    `${block}__match-modal-points-badge`,
                    `${block}__match-modal-points-badge--partial`
                  )}
                >
                  Partial Match&nbsp;
                  <br className="mobile-only" />
                  0.5 point
                </div>
              ) : (
                <div className={`${block}__match-modal-points-badge`}>
                  Full Match&nbsp;
                  <br className="mobile-only" />
                  +1 point
                </div>
              )}
            </div>
            <div className={`${block}__req-back-text`}>
              <strong>{card.niceToHave ? "Nice to have" : "Must have"}:</strong>{" "}
              {card.requirementText}
            </div>
            <button
              className={`${block}__modal-button`}
              onClick={() => requirementClick(card)}
            >
              View Match
            </button>
          </>
        );
      }

      return (
        <div className={`${block}__oops-wrapper`}>
          <div style={{ marginBottom: "10px" }}>
            <strong>Oops!</strong>
          </div>
          These two cards don't match.
          <br />
          {oopsMessage()}
        </div>
      );
    },
    [requirementClick, oopsMessage]
  );

  const restart = useCallback(() => {
    setSkillsShake(false);
    setRequirementsShake(false);
    setTouched(false);
    setScore(0);
    setStagedRequirement(null);
    setInfo("[i] Tap any job requirement on the left column to start!");
    setRequirements(
      cards
        .map((a) => [Math.random(), a])
        .sort((a, b) => a[0] - b[0])
        .map((a) => ({ ...a[1] }))
    );
    setSkills(
      cards
        .map((a) => [Math.random(), a])
        .sort((a, b) => a[0] - b[0])
        .map((a) => ({ ...a[1] }))
    );
  }, [cards]);

  useEffect(() => {
    setModal(
      <div className={`${block}__modal-panel`}>
        <div className={`${block}__welcome-modal-wrappwer`}>
          <div className={`${block}__modal-titlebar`}>
            Welcome to Tenyks Skills Match Game!
          </div>

          <div className={`${block}__modal-content`}>
            <img
              className={`${block}__welcome-modal-logo`}
              src={logo}
              alt="Tenyks Logo"
            />
            <div style={{ lineHeight: "1.4" }}>
              <center>Hello Tenyks team!</center>
              <br />
              <br />I created this card game to show you how many matches we can
              find between me and your Frontend Engineer position, enjoy!
            </div>
          </div>

          <div className={`${block}__modal-footer`}>
            <button className={`${block}__modal-button`} onClick={start}>
              Start!
            </button>
          </div>
        </div>
      </div>
    );
  }, [start]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={block}>
      <header className={`${block}__header`}>
        <div className={`${block}__header-container container-game`}>
          <img
            className={`${block}__header-nooks-logo`}
            src={logo}
            alt="Logo"
          />

          <div className={`${block}__score`}>
            <div className={`${block}__score-label`}>score</div>
            <div className={`${block}__score-value`}>{score}/10</div>
          </div>
        </div>
      </header>
      <main className={`${block}__main`}>
        <div className={`${block}__main-container container-game`}>
          {info && <div className={`${block}__tip`}>{info}</div>}

          <div className={`${block}__cards`}>
            <div className={`${block}__requirements`}>
              {requirements.map((card, cardIndex) => {
                return (
                  <div
                    key={card.requirementText}
                    className={cn(`${block}__card`, {
                      [`${block}__card--flip`]: card.requiredVisible,
                      [`${block}__card--initial-animation`]: !touched,
                      [`${block}__card--shake`]:
                        (requirementsShake && !card.match) ||
                        (card.requirementShaking && !card.match),
                    })}
                    style={{ animationDelay: `${(cardIndex + 1) / 10}s` }}
                  >
                    <div className="flip-card-inner">
                      <button
                        className={`${block}__card-front`}
                        onClick={() => flipRequirement(card)}
                      >
                        <div className={`${block}__requirement-title`}>
                          Tap to reveal
                          <br />
                          requirement #{cardIndex + 1}
                        </div>
                        {!card.requiredVisible && (
                          <div className={`${block}__requirement-number`}>
                            ?
                          </div>
                        )}
                        <div className={`${block}__card-front-type`}>
                          {card.niceToHave ? "Nice to have" : "Must have"}
                        </div>
                      </button>
                      <div className={`${block}__card-back`}>
                        {card.match && (
                          <div
                            className={`${block}__match-modal-points-wrapper`}
                          >
                            {card.partialMatch ? (
                              <div
                                className={cn(
                                  `${block}__match-modal-points-badge`,
                                  `${block}__match-modal-points-badge--partial`
                                )}
                              >
                                Partial Match&nbsp;
                                <br className="mobile-only" />
                                0.5 point
                              </div>
                            ) : (
                              <div
                                className={`${block}__match-modal-points-badge`}
                              >
                                Full Match&nbsp;
                                <br className="mobile-only" />
                                +1 point
                              </div>
                            )}
                          </div>
                        )}
                        <div className={`${block}__req-back-text`}>
                          <strong>
                            {card.niceToHave ? "Nice to have" : "Must have"}:
                          </strong>{" "}
                          {card.requirementText}
                        </div>
                        {card.match && (
                          <button
                            className={`${block}__modal-button`}
                            onClick={() => requirementClick(card)}
                          >
                            View Match
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={`${block}__matches`}>
              {/* <div
                  style={{
                    color: "white",
                    marginBottom: "40px",
                    fontSize: "24px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  Thiago's
                  <br />
                  Skills
                </div> */}
              {skills.map((card, cardIndex) => {
                return (
                  <div
                    key={card.requirementText}
                    className={cn(`${block}__card`, {
                      [`${block}__card--flip`]: card.skillsVisible,
                      [`${block}__card--initial-animation`]: !touched,
                      [`${block}__card--shake`]:
                        (skillsShake && !card.match && !card.skillsVisible) ||
                        (card.skillShaking && !card.match),
                    })}
                    style={{ animationDelay: `${(cardIndex + 1) / 10}s` }}
                  >
                    <div className="flip-card-inner">
                      <button
                        className={`${block}__card-front`}
                        onClick={() => flipSkill(card)}
                      >
                        <div className={`${block}__requirement-title`}>
                          Tap to reveal
                          <br />
                          skill #{cardIndex + 1}
                        </div>
                        {!card.skillsVisible && (
                          <div
                            className={`${block}__requirement-number ${block}__requirement-number--skill`}
                          >
                            ?
                          </div>
                        )}
                      </button>
                      <div className={`${block}__card-back`}>
                        {skillCardBackChunk(card)}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={`${block}__actions`}>
            <Link className={`${block}__action-button`} to="/">
              View Resume
            </Link>
            <button className={`${block}__action-button`} onClick={restart}>
              Restart Game
            </button>
          </div>
        </div>
      </main>
      {modal && <div className={`${block}__modal`}>{modal}</div>}
    </div>
  );
}

export default PageTenyksGame;
