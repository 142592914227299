import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import PageIndex from "./pages";
import PageTenyksGame from "./pages/page-tenyks-game";
import PageFingerprintGame from "./pages/page-fingerprint-game";

function App() {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PageIndex />} />
          <Route path="/tenyks" element={<PageTenyksGame />} />
          <Route path="/fingerprint" element={<PageFingerprintGame />} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  );
}

export default App;
