import { useCallback, useEffect, useState } from "react";
import cn from "classnames";
import Lottie from "react-lottie";
import * as animationData from "../112240-matched.json";
import * as fingerprintAnimationData from "../fingerprint-animation.json";
import logo from "../images/logo-fingerprint.svg";
import "./page-fingerprint-game.scss";
import axios from "axios";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    // preserveAspectRatio: "xMidYMid slice",
  },
};
const fingerprintDefaultOptions = {
  loop: true,
  autoplay: true,
  animationData: fingerprintAnimationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const block = "page-fingerprint-game";
function PageFingerprintGame() {
  const [modal, setModal] = useState();
  const [info, setInfo] = useState("Tap a job requirement to start!");
  const [requirements, setCards] = useState([]);

  const [score, setScore] = useState(0);

  const flipRequirement = useCallback(
    (card) => {
      setInfo(null);
      const localRequirements = requirements.map((requirement) => {
        if (card.requirementText === requirement.requirementText) {
          requirement.flip = true;

          return requirement;
        }
        return requirement;
      });

      setCards(localRequirements);
    },
    [requirements]
  );

  const dismissModal = useCallback(() => {
    setModal(null);
  }, []);

  const matchAlert = useCallback(
    (card) => {
      setModal(
        <div
          className={`${block}__modal-panel ${block}__modal-panel--full ${block}__modal-panel--light`}
        >
          <div className={`${block}__welcome-modal-wrappwer`}>
            <div className={`${block}__modal-titlebar`}>It's a Match!</div>

            <div className={`${block}__modal-content`}>
              <div className={`${block}__match-modal-lottie`}>
                <Lottie options={defaultOptions} />
              </div>
              <div className={`${block}__match-modal-points-wrapper`}>
                {card.partialMatch ? (
                  <div
                    className={cn(
                      `${block}__match-modal-points-badge`,
                      `${block}__match-modal-points-badge--partial`
                    )}
                  >
                    Partial Match 0.5 point
                  </div>
                ) : (
                  <div className={`${block}__match-modal-points-badge`}>
                    +1 point
                  </div>
                )}
              </div>
              <div className={`${block}__modal-text`}>
                <strong>
                  {card.niceToHave ? "Nice to have" : "Must have"}
                </strong>
                : {card.requirementText}
                <br />
                {card.skills?.map((skill) => {
                  return (
                    <div key={skill} className={`${block}__skill`}>
                      {skill}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={`${block}__modal-footer`}>
              <button
                className={`${block}__modal-button`}
                onClick={dismissModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      );
    },
    [dismissModal]
  );

  const getMatch = useCallback(
    async (card) => {
      setModal(
        <Lottie options={fingerprintDefaultOptions} width={80} height={80} />
      );

      const response = await axios.get(
        `https://go-roulette.vercel.app/get-match/${card.id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setCards((prevRequirements) => {
        const updatedRequirements = prevRequirements.map((requirement) => {
          if (requirement.id === card.id) {
            requirement.match = true;
            requirement.skills = response.data;
            matchAlert(requirement);
          }
          return requirement;
        });

        return updatedRequirements;
      });

      setModal(null);
      setScore((prevScore) => prevScore + 1);
    },
    [setModal, setScore, matchAlert]
  );

  const getCards = useCallback(async () => {
    setModal(
      <Lottie options={fingerprintDefaultOptions} width={80} height={80} />
    );
    const getCardsResponse = await axios.get(
      `https://go-roulette.vercel.app/cards`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    setModal(null);
    setCards(getCardsResponse.data);
  }, [setCards, setModal]);

  const start = useCallback(() => {
    // dismissModal();
    // setStarted(true);
    getCards();
  }, [getCards]);

  // const restart = useCallback(() => {
  //   setScore(0);
  //   setInfo("[i] Tap any job requirement on the left column to start!");
  // }, [cards]);

  useEffect(() => {
    setModal(
      <div className={`${block}__modal-panel`}>
        <div className={`${block}__welcome-modal-wrappwer`}>
          <div className={`${block}__modal-titlebar`}>Welcome</div>

          <div className={`${block}__modal-content`}>
            <img
              className={`${block}__welcome-modal-logo`}
              src={logo}
              alt="Fingerprint Logo"
            />
            <div style={{ lineHeight: "1.4" }}>
              <center>Hello Fingerprint Team,</center>
              <br />
              <br />
              I'm Thiago, and I'm excited to be a candidate for your Senior R&D
              Engineer role.
              <br />
              <br />
              I created this game-like experience for two reasons: first, to
              demonstrate how my skills align with your requirements, and
              second, to challenge myself by setting up and launching a simple
              Golang backend on Vercel.
              <br />
              <br />I hope you enjoy exploring it!
            </div>
          </div>

          <div className={`${block}__modal-footer`}>
            <button className={`${block}__modal-button`} onClick={start}>
              Start
            </button>
          </div>
        </div>
      </div>
    );
  }, [start]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const checkMatch = useCallback(
    (event, card) => {
      event.preventDefault();
      event.stopPropagation();

      getMatch(card);
    },
    [getMatch]
  );

  return (
    <div className={block}>
      <header className={`${block}__header`}>
        <div className={`${block}__header-container container-game`}>
          <img
            className={`${block}__header-nooks-logo`}
            src={logo}
            alt="Logo"
          />

          <div className={`${block}__score`}>
            <div className={`${block}__score-label`}>score</div>
            <div className={`${block}__score-value`}>{score}/12</div>
          </div>
        </div>
      </header>
      <main className={`${block}__main`}>
        {info && <div className={`${block}__tip`}>{info}</div>}
        <div className={`${block}__main-container container-game`}>
          <div className={`${block}__cards`}>
            {/* <div className={`${block}__requirements`}> */}
            {requirements.map((card, cardIndex) => {
              return (
                <div
                  key={card.requirementText}
                  className={cn(`${block}__card`, {
                    [`${block}__card--flip`]: card.flip,
                  })}
                  style={{ animationDelay: `${(cardIndex + 1) / 10}s` }}
                >
                  <div className={`${block}__flip-card-inner`}>
                    <button
                      className={`${block}__card-front`}
                      onClick={() => flipRequirement(card)}
                    >
                      <div className={`${block}__card-front-type`}>
                        {card.niceToHave ? "nice to have" : "must have"}
                      </div>
                      <div className={`${block}__card-front-icon-wrapper`}>
                        <div className={`${block}__card-front-icon`}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            width={24}
                            height={24}
                            fill="#f04405"
                          >
                            <path d="M144 144l0 48 160 0 0-48c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192l0-48C80 64.5 144.5 0 224 0s144 64.5 144 144l0 48 16 0c35.3 0 64 28.7 64 64l0 192c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 256c0-35.3 28.7-64 64-64l16 0z" />
                          </svg>
                        </div>
                      </div>
                      <div className={`${block}__requirement-title`}>
                        {card.requirementTitle}
                        {/* <br /> */}
                        {/* requirement #{cardIndex + 1} */}
                      </div>
                      {/* {!card.flip && (
                        <div className={`${block}__requirement-number`}>?</div>
                      )} */}
                    </button>
                    <div className={`${block}__card-back`}>
                      <div className={`${block}__card-back-tags`}>
                        <div className={`${block}__card-front-type`}>
                          {card.niceToHave ? "nice to have" : "must have"}
                        </div>
                        {card.match && (
                          <div
                            className={`${block}__match-modal-points-wrapper`}
                          >
                            {card.partialMatch ? (
                              <div
                                className={cn(
                                  `${block}__match-modal-points-badge`,
                                  `${block}__match-modal-points-badge--partial`
                                )}
                              >
                                Partial Match&nbsp;
                                <br className="mobile-only" />
                                0.5 point
                              </div>
                            ) : (
                              <div
                                className={`${block}__match-modal-points-badge`}
                              >
                                +1 point
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <div className={`${block}__req-back-text`}>
                        {/* <strong>
                          {card.niceToHave ? "Nice to have" : "Must have"}:
                        </strong>{" "} */}
                        {card.requirementText}
                      </div>

                      <button
                        className={cn(`${block}__modal-button`, {
                          [`${block}__modal-button--secondary`]: card.match,
                        })}
                        onClick={(event) =>
                          card.match
                            ? matchAlert(card)
                            : checkMatch(event, card)
                        }
                      >
                        {card.match ? "Open Match" : "Check Match"}
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
            {/* </div>
            <div className={`${block}__matches`}> */}
            {/* <div
                  style={{
                    color: "white",
                    marginBottom: "40px",
                    fontSize: "24px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  Thiago's
                  <br />
                  Skills
                </div> */}
            {/* {skills.map((card, cardIndex) => {
              return (
                <div
                  key={card.requirementText}
                  className={cn(`${block}__card`, {
                    [`${block}__card--flip`]: card.skillsVisible,
                    [`${block}__card--initial-animation`]: !touched
                  })}
                  style={{
                    animationDelay: `${(cardIndex + 1) / 10}s`,
                    opacity: stagedRequirement ? 1 : 0.2,
                  }}
                >
                  <div className="flip-card-inner">
                    <button
                      className={`${block}__card-front`}
                      onClick={() => flipSkill(card)}
                    >
                      <div className={`${block}__requirement-title`}>
                        Tap to reveal
                        <br />
                        skill #{cardIndex + 1}
                      </div>
                      {!card.skillsVisible && (
                        <div
                          className={`${block}__requirement-number ${block}__requirement-number--skill`}
                        >
                          ?
                        </div>
                      )}
                    </button>
                    <div className={`${block}__card-back`}>
                      {skillCardBackChunk(card)}
                    </div>
                  </div>
                </div>
              );
            })} */}
          </div>
        </div>
        {/* <div className={`${block}__actions`}>
          <Link className={`${block}__action-button`} to="/">
            View Resume
          </Link>
          <button className={`${block}__action-button`} onClick={restart}>
            Restart Game
          </button>
        </div> */}
        {/* </div> */}
      </main>
      {modal && <div className={`${block}__modal`}>{modal}</div>}
    </div>
  );
}

export default PageFingerprintGame;
