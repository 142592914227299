import "./page-index.scss";
import { useInView } from "react-intersection-observer";
import profileNewSquare from "../images/profile-new-square.png";
import NooksGame from "../components/nooks-game";
import cn from "classnames";
import { invariant } from "@remix-run/router";
import { useEffect } from "react";
import Projects from "../components/projects";

const block = "page-index";
function PageNooks() {
  const [heroRef, heroRefInView] = useInView({
    rootMargin: "0px",
    initialInView: true,
    trackVisibility: true,
    delay: 100,
  });
  const [avatarRef, avatarRefInView] = useInView({
    rootMargin: "-65px",
    initialInView: true,
    trackVisibility: true,
    delay: 100,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={block}>
      <header
        className={cn(`${block}__header`, {
          [`${block}__header--visible`]: !heroRefInView,
        })}
      >
        <div className={`${block}__header-container container`}>
          <div
            className={cn(`${block}__header-highlight`, {
              [`${block}__header-highlight--visible`]: !heroRefInView,
            })}
          >
            Thiago bapti<span style={{ color: "grey" }}>(</span>sta
            <span style={{ color: "grey" }}>)</span>
          </div>
          <img
            className={cn(`${block}__header-avatar-img`, {
              [`${block}__header-avatar-img--visible`]: !avatarRefInView,
            })}
            alt="Avatar"
            src={profileNewSquare}
          />
        </div>
      </header>
      <main className={`${block}__main`}>
        <div className={`${block}__hero`}>
          <div className={`${block}__hero-headline`} ref={heroRef}>
            Thiago
            <span className="desktop-only">&nbsp;</span>
            <br className="mobile-only" />
            Bapti<span style={{ color: "grey" }}>(</span>sta
            <span style={{ color: "grey" }}>)</span>
          </div>
        </div>
        <div className={`${block}__content container`}>
          <div className={`${block}__about`}>
            <img
              className={`${block}__avatar-img`}
              alt="Avatar"
              src={profileNewSquare}
              ref={avatarRef}
            />
            <div className={`${block}__about-text`}>
              Hi there 👋🏼 I'm a 36 years old <strong>Frontend Engineer</strong>{" "}
              based in São Paulo 🇧🇷
            </div>
            <br />
          </div>
          <div className={`${block}__main-block`}>
            {/* <div className={`${block}__bio`}>
              <h1>Who I am?</h1>
              <div className={`${block}__tltr`}>
                <strong>TL;DR</strong> 18 years as a frontend engineer and 5
                years as a programming instructor
              </div>
              <p>
                I started on dev at 17 years old, by my dad’s influence who
                works on tech so far(luckily I decided to take a more colored
                path than that old Unix servers).
              </p>
              <p>
                I got a databases administrator degree by that age however
                creating interactive UI seemed way more exciting to me than
                writing inner joins. 18 years later I can say I was right.
              </p>
            </div> */}
            <Projects />
          </div>
          {/* <div className={`${block}__main-block`}>
            <h1>What I've done lately?</h1>
            <div className={`${block}__company`}>
              <header>
                <div className={`${block}__company-name`}>
                  🏢&nbsp;
                  <a
                    href="https://www.linkedin.com/company/akadseguros/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    AKAD Seguros (Insurance) - 2021/2022
                  </a>
                </div>

                <div className={`${block}__skill-pill`}>
                  Sr. Software Engineer
                </div>
                <div className={`${block}__skill-pill`}>Tech Lead</div>
                <div className={`${block}__skill-pill`}>React</div>
                <div className={`${block}__skill-pill`}>Gatsby</div>
                <div className={`${block}__skill-pill`}>Javascript</div>
                <div className={`${block}__skill-pill`}>SASS</div>
                <div className={`${block}__skill-pill`}>Storybook</div>
                <div className={`${block}__skill-pill`}>Azure</div>
                <div className={`${block}__skill-pill`}>Git</div>
                <div className={`${block}__skill-pill`}>Analytics</div>
                <div className={`${block}__skill-pill`}>Recruitment</div>
                <br />
                <div className={`${block}__highlights`}>
                  <ul>
                    <li>
                      <strong>Led</strong> a team of 14 frontend engineers,
                      running <strong>group meetings</strong>, giving{" "}
                      <strong>technical support</strong> and being the frontend
                      representative for other departments;
                    </li>
                    <li>
                      Experienced <strong>M&A</strong> for the first time,
                      dealing with integrations between legacy and new projects,
                      mixed teams and conflicting mindsets;
                    </li>
                    <li>
                      Full <strong>remote</strong>
                    </li>
                  </ul>
                </div>
              </header>
            </div>
            <div className={`${block}__company`}>
              <header>
                <div className={`${block}__company-name`}>
                  🏢&nbsp;
                  <a
                    href="https://www.linkedin.com/company/r-ga/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    R/GA (Digital Agency) - 2018/2021
                  </a>
                </div>
                <div className={`${block}__skill-pill`}>
                  Sr. Software Engineer
                </div>
                <div className={`${block}__skill-pill`}>Tech Lead</div>
                <div className={`${block}__skill-pill`}>React</div>
                <div className={`${block}__skill-pill`}>Javascript</div>
                <div className={`${block}__skill-pill`}>Typescript</div>
                <div className={`${block}__skill-pill`}>Gatsby</div>
                <div className={`${block}__skill-pill`}>NextJS</div>
                <div className={`${block}__skill-pill`}>Storybook</div>
                <div className={`${block}__skill-pill`}>SASS</div>
                <div className={`${block}__skill-pill`}>Lottie</div>
                <div className={`${block}__skill-pill`}>Accessibility</div>
                <div className={`${block}__skill-pill`}>GraphQL</div>
                <div className={`${block}__skill-pill`}>Git</div>
                <div className={`${block}__skill-pill`}>Docker</div>
                <div className={`${block}__skill-pill`}>AWS</div>
                <div className={`${block}__skill-pill`}>GCP</div>
                <div className={`${block}__skill-pill`}>Python</div>
                <div className={`${block}__skill-pill`}>FFMPEG</div>
                <div className={`${block}__skill-pill`}>Analytics</div>
                <div className={`${block}__skill-pill`}>Recruitment</div>
                <div className={`${block}__highlights`}>
                  <ul>
                    <li>
                      Last year dedicated to <strong>Google</strong>{" "}
                      projects(Google Video Maker and YouTube Works)
                    </li>
                  </ul>
                </div>
              </header>
            </div>
            <div className={`${block}__company`}>
              <header>
                <div className={`${block}__company-name`}>
                  🏢&nbsp;
                  <a
                    href="https://www.linkedin.com/company/cielo/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    CIELO (Means of Payment) - 2017/2018
                  </a>
                </div>
                <div className={`${block}__skill-pill`}>
                  Senior Software Engineer
                </div>
                <div className={`${block}__skill-pill`}>VueJS</div>
                <div className={`${block}__skill-pill`}>Javascript</div>
                <div className={`${block}__skill-pill`}>Foundation</div>
                <div className={`${block}__skill-pill`}>Git</div>

                <div div className={`${block}__highlights`}>
                  <ul>
                    <li>TBD</li>
                  </ul>
                </div>
              </header>
            </div>
            <div className={`${block}__company`}>
              <header>
                <div className={`${block}__company-name`}>
                  🏢&nbsp;
                  <a
                    href="https://www.linkedin.com/company/viaimaginecaminhos/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Via Varejo (Retail) - 2015/2017
                  </a>
                </div>
                <div className={`${block}__skill-pill`}>
                  Senior Software Engineer
                </div>
                <div className={`${block}__skill-pill`}>SenchaJS</div>
                <div className={`${block}__skill-pill`}>AngularJS</div>
                <div className={`${block}__skill-pill`}>Angular</div>
                <div className={`${block}__skill-pill`}>Javascript</div>
                <div className={`${block}__skill-pill`}>Bootstrap</div>
                <div className={`${block}__skill-pill`}>Git</div>
                <div div className={`${block}__highlights`}>
                  <ul>
                    <li>TBD</li>
                  </ul>
                </div>
              </header>
            </div>
            <div className={`${block}__company`}>
              <header>
                <div className={`${block}__company-name`}>
                  🏢&nbsp;
                  <a
                    href="https://www.linkedin.com/company/eng-dtp-&-multimidia/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    ENG (Education) - 2010/2015
                  </a>
                </div>

                <div className={`${block}__skill-pill`}>
                  Official Adobe Instructor
                </div>
                <div className={`${block}__skill-pill`}>Speaker</div>
                <div className={`${block}__skill-pill`}>Consultant</div>
                <div className={`${block}__skill-pill`}>Adobe Flex</div>
                <div className={`${block}__skill-pill`}>Adobe Flash</div>
                <div className={`${block}__skill-pill`}>Javascript</div>
                <div className={`${block}__skill-pill`}>Actionscript</div>
                <div className={`${block}__skill-pill`}>Adobe Coldfusion</div>
                <div div className={`${block}__highlights`}>
                  <ul>
                    <li>TBD</li>
                  </ul>
                </div>
              </header>
            </div>
          </div> */}
        </div>

        <div className={`${block}__footer`}>
          thiago@syncmob.com.br
          <br />
          <a
            href="https://www.linkedin.com/in/thiagobapti/"
            target="_blank"
            rel="noreferrer"
          >
            linkedin.com/in/thiagobapti
          </a>
        </div>
      </main>
    </div>
  );
}

export default PageNooks;
